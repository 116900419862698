import React from 'react';
import img from "../img/bSeva-removebg-preview.png";import "./nav.css";

function NavComponent() {
  const handleClick = () => {
    // Replace 'https://bookseva.com/devotee-home-page' with the external link you want to navigate to
    window.location.href = 'https://bookseva.com/devotee-home-page';
  };

  return (
    <div className='heading'>
      <div>
        <button className='butttton' onClick={handleClick}>
          <img src={img} alt="" srcSet="" height={100} width={200} className='fluid iii' />
        </button>
      </div>
    </div>
  );
}

export default NavComponent;
