import React, { useEffect, useState, useRef , useCallback  } from 'react';
import './css/App.css';
import $ from 'jquery';
import Booksevicon from './img/book-seva-icon.svg';
import imgBook from "./img/bSeva-removebg-preview.png";
import shareimg from "./img/share.png"
import { Version } from './url/config'; 
import { BaseUrl } from './url/config';
import axios from 'axios';   
import debounce from 'lodash/debounce';
import vWaterFall from "./img/fallingwater.webm"
import DOMPurify from 'dompurify';
import newFlower from "./Archana/Image/ezgif.com-optimizeFlower.gif"
import newDeepam from "./Deepam/image/ezgif.com-optimize Deepam(1).gif"

import newAbhishekam from "./Abhishekam/image/ezgif.com-optimize.gif"



import flower from "./img/expFlower.png"

import SopanamIcon from './img/sopanam-logo.svg';

import bell from './img/bell (7).png';
import bell2 from './img/giphyyy.gif';
import bellSound from './audio/nallur.mp3';
import ReactAudioPlayer from 'react-audio-player';

import sound from "./img/volume (1).png"

import NavComponent from "./Components/NavComponent"

import mute2 from "./img/mute (1).png"
import whatsapp from "./img/whatsapp-logo.png"
import instagram from "./img/instagram (4).png"
import facebook from "./img/facebook (1).png"
import tweeter from "./img/twitter (2).png"

import music from "./img/music-note.png"
import MuteMusicc from "./img/mute (music).png"
import { ImgUrl } from './config/url';
import waterfall from "./img/wwwaater-removebg-preview.png"



function App() { 
  

  


  const [Thali, setThali] = useState(false);
  const [Pellet, setPellet] = useState(false);
  const [Flower, setFlower] = useState(false);
  const [Fumes, setFumes] = useState(false); 
  const [Agarpathy, setAgarpathy] = useState(false);
  const [Kudam, setKudam] = useState(false);
  const [BellRing, setBellRing] = useState(false);
  // const [Mute, setMute] = useState(false); 
  const [showNewDiv, setShowNewDiv] = useState(false);
  const [ganapathyAudioo, setganapathyAudioo] = useState(false)
  const [ohomAudio, setohomAudio] = useState(false)
  const [gayathriAudio, setgayathriAudio] = useState(false)
const [naryanaAudio, setnaryanaAudio] = useState(false)
  const [topBarClass, setTopBarClass] = useState('header-slide-con download-app-container');
  const [topBarArrowClass, setTopBarArrowClass] = useState('fas fa-sort-down');
  const [bellNotificationClass, setBellNotificationClass] = useState('notification_container');
  const [shareNotificationClass, setShareNotificationClass] = useState('notification_container share-icon');
  const [ganesh, setganesh] = useState(false)
  const [Vishnu, setVishnu] = useState(false)
  const [parvathy, setparvathy] = useState(false)
  const [Share, setShare] = useState(false)
  // const [soundPlayingThali, setSoundPlayingThali] = useState(false);
  const [thaliTimeout, setThaliTimeout] = useState(null);
  const [isThaliPlaying, setIsThaliPlaying] = useState(false);
  const [Mute, setMute] = useState(false); // Assuming Mute is a state variable
  const [gifData, setGifData] = useState(null);
  const pellet = document.querySelector('.falling-pellet23');
  const [Loading, setLoading] = useState(false)

  const HandelLoading = ()=>{
    setLoading(true)
  }

  document.addEventListener('DOMContentLoaded', function() {
    const pellet = document.querySelector('.falling-pellet23');
    
    function restartAnimation() {
      pellet.style.animation = 'none';
      setTimeout(() => {
        pellet.style.animation = 'fall 4s linear infinite';
      }, 0);
    }
    
    pellet.addEventListener('animationiteration', function() {
      restartAnimation();
    });
  });
  
  
  


  // useEffect(() => {
  //   const fetchGif = () => {
  //     axios.get("http://localhost:5000/gif1").then((response) => {
  //       // Assuming your server responds with the GIF data in response.data
  //       setGifData(response.data);
  //     });
  //   };

  //   fetchGif();
  // }, []);  

  // useEffect(() => {
  //   if (Kudam) {
  //     const gif = new Image();
  //     gif.src = `${ImgUrl}/Assets/Abhishekam/image/giphy (10).gif`;
  //     // When the image is preloaded, it can be displayed without initial loading delay
  //     gif.onload = () => {
  //       // Set a state or do something to display the preloaded GIF
  //     };
  //   }
  // }, [Kudam]);
 
  const [MuteMusic, setMuteMusic] = useState(true)


console.log("Mute",Mute);

function toggleFooter() {
  
  $('#btm-btn-fixed').toggleClass('white-btn orange-btn');
  $('#btnss').toggleClass('footer-circle-view footer-circle-hide');
  $('#footerbtns-con').toggleClass('view-foot-btns hide-foot-btns');
  // if (Mute==true) {
  //   setMuteMusic(true);
  // }
 
}

useEffect(() => {
  $('#btm-btn-fixed').click(function () {
    $(this).toggleClass('orange-btn white-btn');
    $('#btnss').toggleClass('footer-circle-hide footer-circle-view');
    $('#footerbtns-con').toggleClass('hide-foot-btns view-foot-btns');
  });

}, []);
console.log("mutemusic",MuteMusic)

useEffect(()=>{
  if (Mute === true) {
    setMuteMusic(true);
    console.log('useEffect');
    const ohmAudio = ohmAudioRef.current.audioEl.current;
    ohmAudio.pause();
  } 
 
},[Mute,MuteMusic])

const HandelMuteMusic = () => {
  const ohmAudio = ohmAudioRef.current.audioEl.current;
  const bellAudio = bellAudioRef.current.audioEl.current;

  if (MuteMusic) {
    console.log('if');
    ohmAudio.play();
 
  } else {
    console.log('else');
    ohmAudio.pause();
    
  }
  setMuteMusic(!MuteMusic); 
}





  const HandelGaneshan = ()=>{
    toggleFooter()
    setganesh(true)
    setVishnu(false)
    setohomAudio(false)
    setganapathyAudioo(true)
    setMute(false)
    setKudam(false)
    setAgarpathy(false)
    setThali(false)
    setFlower(false)
    const abhishekamAudio = abhishekamAudioRef.current.audioEl.current;
    abhishekamAudio.pause();
    abhishekamAudio.currentTime = 0;

    const deepamAudio = deepamAudioRef.current.audioEl.current;
  deepamAudio.pause();
  deepamAudio.currentTime = 0;


    const doopamAudio = doopamAudioRef.current.audioEl.current;
    doopamAudio.pause();
    doopamAudio.currentTime = 0;


    const archanaAudio = archanaAudioRef.current.audioEl.current;
    archanaAudio.pause();
    archanaAudio.currentTime = 0;


    toggleTopBar()
  }

  const HandelVishnu = ()=>{
    toggleFooter()
    setganesh(false)
    setVishnu(true)
    setohomAudio(false)
    setgayathriAudio(false)
    setganapathyAudioo(false)
    setnaryanaAudio(true)
    setMute(false) 
    setKudam(false)
    setAgarpathy(false)
    setThali(false)
    setFlower(false)
    const abhishekamAudio = abhishekamAudioRef.current.audioEl.current;
    abhishekamAudio.pause();
    abhishekamAudio.currentTime = 0;

    const deepamAudio = deepamAudioRef.current.audioEl.current;
  deepamAudio.pause();
  deepamAudio.currentTime = 0;


    const doopamAudio = doopamAudioRef.current.audioEl.current;
    doopamAudio.pause();
    doopamAudio.currentTime = 0;


    const archanaAudio = archanaAudioRef.current.audioEl.current;
    archanaAudio.pause();
    archanaAudio.currentTime = 0;
    toggleTopBar()
  }
const HandelParvathy = ()=>{
  toggleFooter()
  setganesh(false)
  setVishnu(false)
  setparvathy(true)
  setganapathyAudioo(false)
  setohomAudio(false)
  setgayathriAudio(true)
  setMute(false)
  setKudam(false)
  setAgarpathy(false)
  setThali(false)
  setFlower(false)
  const abhishekamAudio = abhishekamAudioRef.current.audioEl.current;
    abhishekamAudio.pause();

    const deepamAudio = deepamAudioRef.current.audioEl.current;
  deepamAudio.pause();
  deepamAudio.currentTime = 0;


    const doopamAudio = doopamAudioRef.current.audioEl.current;
    doopamAudio.pause();
    doopamAudio.currentTime = 0;


    const archanaAudio = archanaAudioRef.current.audioEl.current;
    archanaAudio.pause();
    archanaAudio.currentTime = 0;
    
  toggleTopBar()

}
const HandelShivan = ()=>{
  toggleFooter()
  setganesh(false)
  setVishnu(false)
  setparvathy(false)
  setganapathyAudioo(false)
  setohomAudio(true)
  setMute(false)
  setKudam(false)
  setAgarpathy(false)
  setThali(false)
  setFlower(false)
  const ohmAudio = ohmAudioRef.current.audioEl.current;
  ohmAudio.pause()
  const abhishekamAudio = abhishekamAudioRef.current.audioEl.current;
    abhishekamAudio.pause();

    const deepamAudio = deepamAudioRef.current.audioEl.current;
    deepamAudio.pause();


    const doopamAudio = doopamAudioRef.current.audioEl.current;
    doopamAudio.pause();


    const archanaAudio = archanaAudioRef.current.audioEl.current;
    archanaAudio.pause();
  toggleTopBar()
}


  const bellAudioRef = useRef();
  const ohmAudioRef = useRef();
  const abhishekamAudioRef = useRef(); 
  const archanaAudioRef = useRef(); 
  const doopamAudioRef = useRef(); 
  const deepamAudioRef = useRef(null); 


 
  const handleShareClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'pooja',
          text: 'Virtual Pooja',
          url: 'https://virtualpooja.initstore.com/', 
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      console.log("Something went wrong")
    }
  };


  const openTopBar = () => {
    console.log("hiii");
    if (topBarClass === 'header-slide-con download-app-container' || topBarClass === 'header-slide-con download-app-container-mob') {
      setTopBarClass('header-slide-con download-app-container-mob viewtopnav');
      setTopBarArrowClass('fas fa-sort-up');
      setBellNotificationClass('notification_container bell-margintop');
      setShareNotificationClass('notification_container share-icon bell-margintop');
    } else {
      setTopBarClass('header-slide-con download-app-container-mob');
      setTopBarArrowClass('fas fa-sort-down');
      setBellNotificationClass('notification_container');
      setShareNotificationClass('notification_container share-icon');
    }
  };
  const [soundPlayingThali, setSoundPlayingThali] = useState(false);

const handleThali = () => {
  const deepamAudio = deepamAudioRef.current.audioEl.current;

  if (thaliTimeout) {
    clearTimeout(thaliTimeout);
  }

  deepamAudio.addEventListener('playing', () => {
    setSoundPlayingThali(true);
    setThali(true);
  });

  deepamAudio.addEventListener('ended', () => {
    setSoundPlayingThali(false);
    setThali(false);
  });

  if (!Mute) {
    deepamAudio.play();
  } else {
    deepamAudio.pause();
    setSoundPlayingThali(false);
    setThali(false);
  }

  const timeout = setTimeout(() => {
    setSoundPlayingThali(false);
    setThali(false);
    if (!Mute) {
      deepamAudio.pause();
    }
  }, 15000);

  setThaliTimeout(timeout);
};

useEffect(() => {
  return () => {
    if (thaliTimeout) {
      clearTimeout(thaliTimeout);
    }
  };
}, [thaliTimeout]);

useEffect(() => {
  if (soundPlayingThali && !Mute) {
    const deepamAudio = deepamAudioRef.current.audioEl.current;
    deepamAudio.play();
  } else {
    const deepamAudio = deepamAudioRef.current.audioEl.current;
    deepamAudio.pause();
  }
}, [soundPlayingThali, Mute]);
  
  
  
  
  
 
  const handlePellet = () => {
    setPellet(true);

    setTimeout(() => {
      setPellet(false);
    }, 7000);
  };
  
  const [showTopBar, setShowTopBar] = useState(false);

  const toggleTopBar = () => {
    setShowTopBar((prevShowTopBar) => !prevShowTopBar);
  };
  const [soundPlayingFlower, setSoundPlayingFlower] = useState(false);
  const [flowerTimeout, setFlowerTimeout] = useState(null);
  
  console.log("flowerStatus:",Flower);

  const HandelFlower = () => {
    
    const archanaAudio = archanaAudioRef.current.audioEl.current;
    archanaAudio.play();
  
    
    archanaAudio.addEventListener('playing', () => {
      if (!soundPlayingFlower) {
        setFlower(true);

        setSoundPlayingFlower(true);
      }
  
      
      if (flowerTimeout) {
        clearTimeout(flowerTimeout);
      }
  
 
      const timeout = setTimeout(() => {
        setFlower(false);
        if (!Mute) {
          archanaAudio.pause();
        }
        setSoundPlayingFlower(false);
      }, 15000);
  

      setFlowerTimeout(timeout);
    });
  };
  
  
  useEffect(() => {
    return () => {
      if (flowerTimeout) {
        clearTimeout(flowerTimeout);
      }
    };
  }, [flowerTimeout]);
  
  useEffect(() => {
    if (soundPlayingFlower && !Mute) {
      const archanaAudio = archanaAudioRef.current.audioEl.current;
      archanaAudio.play();
    } else {
      const archanaAudio = archanaAudioRef.current.audioEl.current;
      archanaAudio.pause();
    }
  }, [soundPlayingFlower, Mute]);

  const [soundPlayingDoopam, setSoundPlayingDoopam] = useState(false);
  const [agarpathyTimeout, setAgarpathyTimeout] = useState(null);
  
const HandelAgarpathy = () => {
  
  const doopamAudio = doopamAudioRef.current.audioEl.current;
  doopamAudio.play();


  doopamAudio.addEventListener('playing', () => {
    if (!soundPlayingDoopam) {
      setAgarpathy(true);
      setSoundPlayingDoopam(true);
    }

    if (agarpathyTimeout) {
      clearTimeout(agarpathyTimeout);
    }

    const timeout = setTimeout(() => {
      setAgarpathy(false);
      if (!Mute) {
        doopamAudio.pause();
      }
      setSoundPlayingDoopam(false);
    }, 15000);

  
    setAgarpathyTimeout(timeout);
  });
};

useEffect(() => {
  return () => {
    if (agarpathyTimeout) {
      clearTimeout(agarpathyTimeout);
    }
  };
}, [agarpathyTimeout]);

useEffect(() => {
  if (soundPlayingDoopam && !Mute) {
    const doopamAudio = doopamAudioRef.current.audioEl.current;
    doopamAudio.play();
  } else {
    const doopamAudio = doopamAudioRef.current.audioEl.current;
    doopamAudio.pause();
  }
}, [soundPlayingDoopam, Mute]);


  const [soundPlayingAbhishekam, setSoundPlayingAbhishekam] = useState(false);
  const [abhishekamTimeout, setAbhishekamTimeout] = useState(null);
  
 
const HandelKudamAndWater = () => {
  // HandelLoading()
  const abhishekamAudio = abhishekamAudioRef.current.audioEl.current;
  abhishekamAudio.play();



  abhishekamAudio.addEventListener('playing', () => {
    
    if (!soundPlayingAbhishekam) {
      setKudam(true)
        // setLoading(false)

     
   
      // console.log('Kudam',Kudam)
     
      setSoundPlayingAbhishekam(true);
    }

    
    if (abhishekamTimeout) {
      clearTimeout(abhishekamTimeout);
    }

    const timeout = setTimeout(() => {
      setKudam(false);
      
      if (!Mute) {
        abhishekamAudio.pause();
      }
      setSoundPlayingAbhishekam(false);
    }, 15000);

    
    setAbhishekamTimeout(timeout);
  });
};


useEffect(() => {
  return () => {
    if (abhishekamTimeout) {
      clearTimeout(abhishekamTimeout);
    }
  };
}, [abhishekamTimeout]);

useEffect(() => {
  if (soundPlayingAbhishekam && !Mute) {
    const abhishekamAudio = abhishekamAudioRef.current.audioEl.current;
    abhishekamAudio.play();
  } else {
    const abhishekamAudio = abhishekamAudioRef.current.audioEl.current;
    abhishekamAudio.pause();
  }
}, [soundPlayingAbhishekam, Mute]);


  const handlePlayBell = () => {
    const bellAudio = bellAudioRef.current.audioEl.current;


    if (bellAudio.paused) {
      bellAudio.play();
      setBellRing(true);

    
    } else {
      bellAudio.pause();
      setBellRing(false);
    }
  };

  
  const handlePlayOhm = () => {
    setMuteMusic(false)
 
    const ohmAudio = ohmAudioRef.current.audioEl.current;
    const bellAudio = bellAudioRef.current.audioEl.current;
  
console.log(ohmAudio.paused);
    if (ohmAudio.paused && Mute==false   ) {
      
      ohmAudio.play();
     
    
    } else {
     
   
      
      setBellRing(false)

     
    }


   
  
  };
  const HandelMuteEfeect = () => {
    setMute(!Mute);
    setMuteMusic(true) // Toggle the 'Mute' state
  
    const ohmAudio = ohmAudioRef.current.audioEl.current;
    const bellAudio = bellAudioRef.current.audioEl.current;
    const abhishekamAudio = abhishekamAudioRef.current.audioEl.current;
    const archanaAudio = archanaAudioRef.current.audioEl.current;
    const doopamAudio = doopamAudioRef.current.audioEl.current;
    const deepamAudio = deepamAudioRef.current.audioEl.current;
  console.log("Mute",Mute);
  console.log("MuteMusic ",MuteMusic);
    if (Mute&& MuteMusic==false) {
      ohmAudio.play();
      bellAudio.play();
      abhishekamAudio.play();
      archanaAudio.play();
      doopamAudio.play();
      deepamAudio.play();
    } else {
      ohmAudio.pause();
      bellAudio.pause();
      abhishekamAudio.pause();
      archanaAudio.pause();
      doopamAudio.pause();
      deepamAudio.pause();
    }
  };
  

  
  

  return (
    <body className="bg-img">
      
      <div className="container ">
        <div className="row justify-content-center">
          <NavComponent />
          <div className="col-lg-4">
            <div className="home-container">
              <img src={SopanamIcon} className="sopanam-logo" alt="Sopanam | Book Seva" />
              <img src={Booksevicon} className="  " alt="Sopanam | Book Seva" />
              <div className="fixed-bottom">
                <div className="row justify-content-center">
                  <div id="footerbtns-con" className="col-lg-4 col-md-4 col-sm-8 hide-foot-btns">
                    <div className="row">
                      <div className="col-3">
                        <button className="footerbtn" onClick={HandelKudamAndWater}>
                          <span>
                            <img src={`${ImgUrl}/Assets/Abhishekam/icon/abhishekamIcon.png`} alt="temple" className='temple' />
                          </span>
                          <h6>Abhishekam</h6>
                        </button>
                      </div>
                      <div className="col-3">
                        <button className="footerbtn" onClick={HandelFlower}>
                          <span>
                            <img src={`${ImgUrl}/Assets/Archana/icon/flowerIcon.png`} alt="pooja" className='fluid'/>
                          </span>
                          <h6>Archana</h6>
                        </button>
                      </div>
                      <div className="col-3">
                        <button className="footerbtn" onClick={HandelAgarpathy}>
                          <span>
                            <img src={`${ImgUrl}/Assets/Doopam/icon/doopamIcon.png`} alt="diety" />
                          </span>
                          <h6>Doopam</h6>
                        </button>
                      </div>
                      <div className="col-3">
                        <button className="footerbtn" onClick={handleThali}>
                          <span>
                            <img src={`${ImgUrl}/Assets/Deepam/icon/light.png`} alt="location" />
                          </span>
                          <h6>Deepam</h6>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="btnss" className="footerbtm-circle orange-btn footer-circle-hide" />
              </div>
              <button id="btm-btn-fixed" className="orange-btn" onClick={handlePlayOhm}>
                <i className="fas fa-chevron-up" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
      <div className={`header-slide-con ${showTopBar ? 'viewtopnav' : ''}`} id="header-slide">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-8 col-lg-5">
        <div className="row">
          <div className="col-12 test">
            <img src={imgBook} alt="" srcSet="" className="imgBook" />
          </div>
        </div>

        <div className="row">
          <div className="col-3 card">
            <button
              onClick={HandelGaneshan}
              style={{ border: 'none', padding: 0, background: 'none' }}
            >
              <img className="ggoodd4" src={`${ImgUrl}/Assets/Ganesh/icon/ganapathy2.png`} alt="" />
            </button>
          </div>
          <div className="col-3 card">
            <button
              onClick={HandelVishnu}
              style={{ border: 'none', padding: 0, background: 'none' }}
            >
              <img className="ggoodd1" src={`${ImgUrl}/Assets/Vishnu/Icon/vishnu.png`} alt="" />
            </button>
          </div>
          <div className="col-3 card">
            <button
              onClick={HandelParvathy}
              style={{ border: 'none', padding: 0, background: 'none' }}
            >
              <img className="ggoodd2" src={`${ImgUrl}/Assets/Lakshmi/Icon/god-lakshmi-png-for-happy-diwali.png`} alt="" />
            </button>
          </div>
          <div className="col-3 card">
            <button
              onClick={HandelShivan}
              style={{ border: 'none', padding: 0, background: 'none' }}
            >
              <img className="ggoodd" src={`${ImgUrl}/Assets/Shiva/icon/lshiva2.png`} alt="" />
            </button>
          </div>
        </div>

        <div className="row">
  <div className="col-3 text-center" style={{ color: '#FFE0CC', fontSize: '13px' }}>
    <b>Ganesh</b>
  </div>
  <div className="col-3 text-center" style={{ color: '#FFE0CC', fontSize: '13px' }}>
    <b>Vishnu</b>
  </div>
  <div className="col-3 text-center" style={{ color: '#FFE0CC', fontSize: '13px' }}>
    <b>Lakshmi</b>
  </div>
  <div className="col-3 text-center" style={{ color: '#FFE0CC', fontSize: '13px' }}>
    <b>Shiva</b>
  </div>
</div>




 




      </div>
    </div>

    <button className="down-btn" onClick={toggleTopBar} id="down-btn">
      <i
        id="downarrow"
        className={`fas ${showTopBar ? 'fa-sort-up' : 'fa-sort-down'}`}
        style={{ fontSize: '24px', position: 'relative', top: '-10px' }}
      ></i>
    </button>
  </div>
</div>


    </div>
      
     
    
      <div className="container">
        <div className="row">
          <div className="col-md-6" style={{ width: '100%' }}>
           
            <div className='god'>
        
{ganesh?(<img src={`${ImgUrl}/Assets/Ganesh/image/ganapathy2.jpg`} className=" imgg gim" />):Vishnu?(<img src={`${ImgUrl}/Assets/Vishnu/Image/Bhagavad Gita - A Summary of the 18 Chapters of Bhagavad Gita - TemplePurohit - Your Spiritual Destination _ Bhakti, Shraddha Aur Ashirwad.jpeg`} className="vishnu vish" />) : parvathy? (<img src={`${ImgUrl}/Assets/Lakshmi/Image/download (7).jpeg`} className='imggParvathy'/>):(  <img src={`${ImgUrl}/Assets/Shiva/Image/download (8).jpeg`} className="imgg" />
)}
              {/* <img src={ganesh ? ganapathy2:Vishnu?vishnu1 :god  } alt="" srcSet="" className=" imgg" /> */}
            </div>
          </div>
          <div className="col-md-6">
            {Thali && (
              <div className="ThaliContainer">
                <div className="thali">
                  {/* <img src={`${ImgUrl}/Assets/Deepam/image/deepam.gif`} alt="" srcSet="" className="img-fluid" /> */}
                  <img src={DOMPurify.sanitize(newDeepam) } className='img-fluid' alt="" srcset="" />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="row">
        <div className="col-md-6">
  {Flower && (
    <div className="PelletContainer">
      <div className="Pellet">
        <img
        src={DOMPurify.sanitize(newFlower)}
          // src={DOMPurify.sanitize(`${ImgUrl}/Assets/Archana/Image/giphy (12).gif`)}
          alt=""
          srcSet=""
          className="falling-pellet23 img-fluid"
        />
      </div>
    </div>
  )}
</div>

          <div className="col-md-6">
            {Agarpathy && (
              <div className="AgarpathiContainer">
                <div className="agarpathy">
                  <img src={DOMPurify.sanitize(`${ImgUrl}/Assets/Doopam/image/giphy (7).gif`)} alt="" srcSet="" className="Agarpathy2" />
                </div>
              </div>
            )}
          </div>
          <div className="col-md-6">
            {Agarpathy && ( 
              <div className="AgarpathiContainer">
                <div className="agarpathy">
                  <img src={DOMPurify.sanitize(`${ImgUrl}/Assets/Doopam/image/giphy (8).gif`)} alt="" srcSet="" className="Agarpathy  fume2" />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <ReactAudioPlayer
              ref={bellAudioRef}
              src={bellSound}
              controls
              className="audio"
              autoPlay={false}
              loop
            />
          </div>
          {ganapathyAudioo? <ReactAudioPlayer
              ref={ohmAudioRef}
              src={`${ImgUrl}/Assets/Ganesh/Audio/namaha.mp3`}
              controls
              className="audio"
              autoPlay={false}
              loop
            />: ohomAudio? <ReactAudioPlayer
              ref={ohmAudioRef}
              src={`${ImgUrl}/Assets/Shiva/Audio/ohoomnama.mp3`}
              controls
              className="audio"
              autoPlay={false}
              loop
            />: gayathriAudio?<ReactAudioPlayer
            ref={ohmAudioRef}
            src={`${ImgUrl}/Assets/Lakshmi/Audio/GayatriMantra.mp3`}
            controls
            className="audio"
            autoPlay={false}
            loop
          />:naryanaAudio?<ReactAudioPlayer
          ref={ohmAudioRef}
          src={`${ImgUrl}/Assets/Vishnu/Audio/narayanaya.mp3`}
          controls
          className="audio"
          autoPlay={false}
          loop 
        />:<ReactAudioPlayer
        ref={ohmAudioRef}
        src={`${ImgUrl}/Assets/Shiva/Audio/ohoomnama.mp3`}
        controls
        className="audio"
        autoPlay={false}
      />}
          <div className="col-md-6">
           
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="bell">
              <button className="btn3" onClick={handlePlayBell}>
                <img src={bell} alt="" srcSet="" className={BellRing ? "bellring" : "imgBell"} height={"50px "} width={50} />
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="sound">
              <button className="sound3" onClick={HandelMuteEfeect} >
                <img src={Mute ? mute2 : sound} alt="" srcSet="" className="img-fluid Mimg" />
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="sound">
              <button className="MuteMusic" onClick={HandelMuteMusic}>
                <img src={MuteMusic ? MuteMusicc : music} alt="" srcSet="" className="img-fluid Mimg" />
              </button>
            </div>
          </div>
        </div>
      </div>

 
      <ReactAudioPlayer
        ref={abhishekamAudioRef}
        src={`${ImgUrl}/Assets/Abhishekam/Audio/abhishekam.mp3`}
        controls
        className="audio"
        autoPlay={false}
      />

      <ReactAudioPlayer
        ref={archanaAudioRef}
        src={`${ImgUrl}/Assets/Archana/Audio/Udukkai.mp3`}
        controls
        className="audio"
        autoPlay={false}
      />

      <ReactAudioPlayer
        ref={doopamAudioRef}
        src={`${ImgUrl}/Assets/Doopam/Audio/chenda.mp3`}
        controls
        className="audio"
        autoPlay={false}
      />

      <ReactAudioPlayer
        ref={deepamAudioRef}
        src={`${ImgUrl}/Assets/Deepam/Audio/deepam.mp3`}
        controls
        className="audio"
        autoPlay={false}
        // onError={(e) => {
        //   console.error('Deepam audio playback error:', e);
        // }}
      />

      <ReactAudioPlayer
        ref={abhishekamAudioRef}
        src={`${ImgUrl}/Assets/Abhishekam/Audio/abhishekam.mp3`}
        controls
        className="audio"
        autoPlay={false}
      />
  
<div className="container">
  <div className="row">
    <div className="col-12 col-md-6"> {/* Use col-12 for full width on small screens */}
      {Kudam && (
        <div className="KudamContainer d-flex justify-content-center align-items-center">
          <div className="Kudam text-center">
            {/* <img src={`${ImgUrl}/Assets/Abhishekam/image/giphy (10).gif`} alt="" className="waterr img-fluid" /> */}
{/* <img src="https://res.cloudinary.com/dajdfdqte/image/upload/v1698993295/giphy_10_xk1fiw.gif"  className='waterr img-fluid' alt="" srcset="" /> */}
            <img src={newAbhishekam} className='waterr img-fluid' alt="" srcset="" />

            {/* <img src={waterfall} className='waterrr img-fluid' alt="" srcset="" /> */}
          </div>
        </div>
      )} 

      {
        Loading &&(
          <div className="KudamContainer d-flex justify-content-center align-items-center">
          <div className="Kudam text-center">
            {/* <img src={`${ImgUrl}/Assets/Abhishekam/image/giphy (10).gif`} alt="" className="waterr img-fluid" /> */}
            {/* <img src={waterfall} className='waterrr img-fluid' alt="" srcset="" /> */}
            <div class="loader"></div>

          </div>
        </div>
        )
      }
    </div>
  </div>
</div>



    </body>
  );
}

export default App;
