import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import NavComponent from './Components/NavComponent';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <div>  
<App/>
</div>
);

